const randomHomeImage = () => {
  const imagesUrl = [
    "img/perroTransp.png",
    "img/gatoTransp.png",
    "img/loveBirdTransp.png",
  ];

  const randomIndex = Math.floor(Math.random() * imagesUrl.length);

  return imagesUrl[randomIndex];
};

export default randomHomeImage;
