import { useContext, useEffect, useState } from "react";
import useFilters from "./useFilters";
import { ProductsContext } from "../context/Products";

const useFilteredProducts = () => {
  const { filters } = useFilters();
  const { products } = useContext(ProductsContext);

  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    // Filter products by animal and brand
    const filteredProducts = products.filter((product) => {
      const animalFilter =
        filters.animal === "all" ||
        product.animal_type.includes(filters.animal);
      const brandFilter =
        filters.brand === "all" ||
        filters.brand === product.brand_id.toString();
      return animalFilter && brandFilter;
    });

    setFilteredProducts(filteredProducts);
  }, [filters, products]);

  return filteredProducts;
};

export default useFilteredProducts;
