import { createContext, useCallback, useEffect, useState } from "react";
import productsData from "../lib/data/products.json";

const ProductsContext = createContext();

const ProductsState = ({ children }) => {
  const [products, setProducts] = useState(productsData);

  const randomizeProducts = useCallback(() => {
    const randomizedProducts = productsData.sort(() => Math.random() - 0.5);
    setProducts(randomizedProducts);
  }, []);

  useEffect(() => {
    randomizeProducts();
  }, [randomizeProducts]);

  return (
    <ProductsContext.Provider value={{ products, randomizeProducts }}>
      {children}
    </ProductsContext.Provider>
  );
};

export { ProductsContext, ProductsState };
