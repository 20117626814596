import { useContext, useEffect, useState } from "react";
import { ProductsContext } from "../context/Products";

const useBrandProducts = (brandId) => {
  const { products } = useContext(ProductsContext);
  const [brandProducts, setBrandProducts] = useState([]);

  useEffect(() => {
    const filteredProducts = products.filter(
      (product) => product.brand_id === brandId
    );
    setBrandProducts(filteredProducts);
  }, [brandId, products]);

  return brandProducts;
};

export default useBrandProducts;
