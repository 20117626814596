import { createContext, useState } from "react";

const FiltersContext = createContext();

const FIltersState = ({ children }) => {

    const [filters, setFilters] = useState({
        animal: 'all',
        brand: 'all', 
    });

    return (
        <FiltersContext.Provider value={{filters, setFilters}}>
            {children}
        </FiltersContext.Provider>
    )
}

export { FiltersContext, FIltersState };