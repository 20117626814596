import React from "react";

function TopCircle({ children, color = "#F18621", homeMode = true }) {
  return (
    <div
      style={{ backgroundColor: color }}
      className={`m-auto  w-[15em] h-[15em] md:w-[35em]  md:h-[35em]  rounded-full md:absolute md:top-[-60px] md:left-[-110px] z-0 flex justify-center items-center ${homeMode ? " lg:w-[55em] lg:h-[55em]  lg:top-[-170px]" : "" }`}
    >
      <div className="z-30 pt-16">{children}</div>
    </div>
  );
}

export default TopCircle;
