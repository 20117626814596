import React, { useContext, useEffect } from "react";
import Navigation from "../components/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBone, faCat, faDog } from "@fortawesome/free-solid-svg-icons";
import BrandBanner from "../components/BrandBanner";
import { BrandsContext } from "../context/Brands";
import Footer from "../components/Footer";
import Aos from "aos";
import InitSection from "../components/InitSection";

function Home() {
  const { randomBrand } = useContext(BrandsContext);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <Navigation />
     <InitSection />
      

     <BrandBanner brand={randomBrand()} />
      <section className="my-44">
        <header data-aos="zoom-in-down" className="flex flex-col items-center">
          <FontAwesomeIcon icon={faBone} className="text-primary text-2xl" />
          <h2 className="font-semibold text-2xl text-gray-500">Animales</h2>
          <span className="text-xs font-light">
            Productos especializados para
          </span>
        </header>

        <main>
          <div className="flex flex-col p-8 md:p-0 md:flex-row gap-24 justify-center mt-12 ">
            <div data-aos="flip-left" className="flex flex-col text-center justify-center shadow p-8 gap-5">
              <FontAwesomeIcon icon={faDog} className="text-primary text-5xl" />
              <h3 className="text-xl  text-gray-500">Perros</h3>
            </div>

            <div data-aos="flip-left" className="flex flex-col text-center  justify-center shadow p-8 gap-5">
              <FontAwesomeIcon icon={faCat} className="text-primary text-5xl" />
              <h3 className="text-xl  text-gray-500">Gatos</h3>
            </div>
          </div>
        </main>
      </section>


      <Footer />
    </>
  );
}

export default Home;
