import { useContext } from "react";
import { FiltersContext } from "../context/Filters";

const useFilters = () => {
    const { filters, setFilters } = useContext(FiltersContext);
    const handleFilterChange = (event) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value,
        });
    }

    return {
        filters,
        handleFilterChange,
    }
}

export default useFilters;