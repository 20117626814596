import React from "react";
import { Link } from "react-router-dom";
import randomHomeImage from "../lib/randomHomeImage";

function InitSection() {
  return (
    <section className="relative p-12 h-screen mb-16">
      <header className="mt-16">
        <h1 className="text-6xl font-bold text-primary mb-4">
          AC <span className="text-secondary">Importadora</span>
        </h1>

        <p className="text-sm text-gray-500 mb-8 md:w-2/6">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nostrum
          adipisci corporis eveniet laboriosam quod repudiandae illo aspernatur
          expedita molestiae nemo cum eos, sequi possimus nobis aperiam ex esse
          totam, quibusdam dolorum. Voluptatum reiciendis ipsum voluptatem
          molestias quam sit animi debitis.
        </p>

        <Link
          to="/productos"
          className="bg-secondary text-white py-5 px-10 rounded"
        >
          Ver productos
        </Link>
      </header>

      <img
        className="absolute right-0 top-0 md:h-screen hidden md:block"
        src="img/homeRectangle.png"
        alt="home reactangle cover"
      />

      <img
        src={randomHomeImage()}
        className="md:absolute mt-8 md:mt-0 ml-28 md:ml-0 right-16 top-1/4 w-[200px] md:w-[400px] lg:w-[500px]"
        alt="home cover animal"
      />
    </section>
  );
}

export default InitSection;
