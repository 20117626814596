import React from "react";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";



export default function PortionsByActivity({portions}) {
  return (
    <Table className="md:w-1/2" aria-label="Example table with dynamic content">
      <TableHeader>
       <TableColumn className="text-center">Peso</TableColumn>
       <TableColumn className="text-center">Baja actividad</TableColumn>
       <TableColumn className="text-center">Alta actividad</TableColumn>
      </TableHeader>
      <TableBody >
    
         {portions.map((portion) => (
           <TableRow key={portion.weight}>
             <TableCell className="text-center">{portion.weight}kg</TableCell>
             <TableCell className="text-center">{portion.low_activity}g</TableCell>
             <TableCell className="text-center">{portion.high_activity}g</TableCell>
           </TableRow>
         ))}
  
      </TableBody>
    </Table>
  );
}
