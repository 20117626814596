import React, { useContext, useState } from "react";
import Navigation from "../components/Navigation";
import BrandBanner from "../components/BrandBanner";
import { BrandsContext } from "../context/Brands";

function Brands() {
  const { brands, randomBrand } = useContext(BrandsContext);
  const [activeBrand, setActiveBrand] = useState(randomBrand());

  const handleBrandSelect = (brand) => {
  setActiveBrand(brand);
  window.scrollTo(0, 0);
  }
  
  return (


    <>
      <Navigation />
      <main>
        <BrandBanner disableExplorewBtn={true} brand={activeBrand} />

        <section className="px-16 mt-24">
          <div className="flex gap-4 justify-center md:justify-normal items-center">
            <span className="text-6xl font-bold absolute text-gray-200">Perros</span>
           
          </div>


          <div className="flex flex-wrap justify-center md:justify-normal gap-12 mt-16">
            {brands
              .filter((brand) => brand.animal_type.includes("dog")).map((brand) => (
                <div onClick={()=>handleBrandSelect(brand)} className="cursor-pointer">
                  <img src={`./img/brands/${brand.name}.png`} alt="" />
                </div>
              ))}
          </div>
        </section>

        <section className="px-16 mt-24">
          <div className="flex gap-4 justify-center md:justify-normal items-center">
            <span className="text-6xl font-bold absolute text-gray-200">Gatos</span>
           
          </div>


          <div className="flex flex-wrap justify-center md:justify-normal gap-12 mt-16">
            {brands
              .filter((brand) => brand.animal_type.includes("cat")).map((brand) => (
                <div onClick={()=>{handleBrandSelect(brand)}} className="cursor-pointer">
                  <img src={`./img/brands/${brand.name}.png`} alt="" />
                </div>
              ))}
          </div>
        </section>
      </main>
    </>
  );
}

export default Brands;
