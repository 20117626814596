import React from "react";
import {Table, TableHeader, TableColumn, TableBody, TableRow, TableCell} from "@nextui-org/react";



export default function PortionsByAge({portions}) {
  return (
    <Table className="md:w-3/4" aria-label="Example table with dynamic content">
      <TableHeader>
       <TableColumn className="text-center">Peso</TableColumn>
       <TableColumn className="text-center">1 a 2 messes</TableColumn>
       <TableColumn className="text-center">3 a 4 meses</TableColumn>
       <TableColumn className="text-center">5 a 7 meses</TableColumn>
       <TableColumn className="text-center">8 a 12 meses</TableColumn>
       <TableColumn className="text-center">13 a 18 meses</TableColumn>
      </TableHeader>
      <TableBody >
    
         {portions.map((portion) => (
           <TableRow key={portion.weight}>
            <TableCell className="text-center">{portion.weight}</TableCell>
             <TableCell className="text-center">{portion["1"]}kg</TableCell>
             <TableCell className="text-center">{portion["3"]}kg</TableCell>
             <TableCell className="text-center">{portion["5"]}kg</TableCell>
             <TableCell className="text-center">{portion["8"]}kg</TableCell>
             <TableCell className="text-center">{portion["13"]}kg</TableCell>
           </TableRow>
         ))}
  
      </TableBody>
    </Table>
  );
}
