import { createContext, useState } from "react";
import brandsData from "../lib/data/brands.json";

const BrandsContext = createContext();

const BrandsState = ({ children }) => {
  const [brands] = useState(brandsData);


  function randomBrand() {
    const randomIndex = Math.floor(Math.random() * brands.length);
    return brands[randomIndex];
  }



  return (
    <BrandsContext.Provider value={{ brands, randomBrand }}>
      {children}
    </BrandsContext.Provider>
  );
};

export { BrandsContext, BrandsState };
