import { Button } from "@nextui-org/react";
import React from "react";
import ProductCard from "./ProductsCard";
import useBrandProducts from "../hooks/useBrandProducts";

function BrandBanner({ brand, disableExplorewBtn }) {
  const brandProducts = useBrandProducts(brand.id);

  return (
    <section>
      <div
        style={{ backgroundColor: brand.color }}
        className={`px-12 md:px-0 py-24  flex flex-col md:flex-row justify-around`}
      >
        <div className="md:w-1/4">
          <img src={`./img/brands/${brand.name}.png`} alt="" />
          <p className="text-gray-300 text-xs">
            {brand.description}
          </p>

        {!disableExplorewBtn &&  <Button
            style={{ color: brand.color }}
            className={`mt-8 w-1/2 bg-white `}
            auto
          >
            Explorar marcas
          </Button>}
        </div>
        <div className="flex flex-col md:flex-row mt-16 md:mt-0 gap-8">
          {brandProducts.map((product) => (
            <ProductCard text="white" key={product.id} product={product} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default BrandBanner;
