import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { NextUIProvider } from "@nextui-org/react";
import { ProductsState } from "./context/Products";
import { BrandsState } from "./context/Brands";
import { FIltersState } from "./context/Filters";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NextUIProvider>
      <BrandsState>
          <FIltersState>
        <ProductsState>
            <App />
        </ProductsState>
          </FIltersState>
      </BrandsState>
    </NextUIProvider>
  </React.StrictMode>
);
