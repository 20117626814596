import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/Home';
import Product from './views/Product';
import "./lib/viewTransitions/Product/ProductViewTransition.css"
import Brands from './views/Brands';
import Products from './views/Products';

function App() {

  return (
     <>
     <BrowserRouter>
     <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/inicio" element={<Home />} />
      <Route path="/marcas" element={<Brands />} />
      <Route path="/productos" element={<Products />} />
      <Route path="/producto/:id" element={<Product />} />
      </Routes>
     </BrowserRouter>
     </>
  );
}

export default App;
