import React, { useContext } from 'react'
import useFilters from '../hooks/useFilters'
import { BrandsContext } from '../context/Brands'

function ProductFilters() {

    const {filters, handleFilterChange} = useFilters()
    const {brands} = useContext(BrandsContext)

  return (
    <div>

        <select onChange={handleFilterChange} name="animal" id="">
            <option value="all">Animal</option>
            <option value="dog">Perro</option>
            <option value="cat">Gato</option>
        </select>


        <select onChange={handleFilterChange} name="brand" id="">
            <option value="all">Marca</option>
            {
                brands.map(brand => (
                    <option value={brand.id}>{brand.name}</option>
                ))
            }
            
        </select>

        <div>
            <span>{filters.animal}</span>
            <span>{filters.brand}</span>
        </div>
    </div>
  )
}

export default ProductFilters