import ProductCard from "../components/ProductsCard";
import Navigation from "../components/Navigation";
import ProductFilters from "../components/ProductFilters";
import useFilteredProducts from "../hooks/useFilteredProducts";

function Products() {
  const products = useFilteredProducts();
  return (
    <>
      <Navigation />
      <ProductFilters />
      <section className="flex gap-24 flex-wrap justify-center md:justify-normal mt-24 px-12">
        {products.map((product) => (
          <ProductCard product={product} />
        ))}
      </section>
    </>
  );
}

export default Products;
