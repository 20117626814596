import React, { useEffect, useState } from "react";
import TopCircle from "../components/TopCircle";
import { useParams } from "react-router-dom";
import useProduct from "../hooks/useProduct";
import Navigation from "../components/Navigation";
import useBrand from "../hooks/useBrand";
import PortionsByActivity from "../components/PortionsByActivity";
import PortionsByAge from "../components/PortionsByAge";
import { Button } from "@nextui-org/react";

function Product() {
  const { id } = useParams();
  const product = useProduct(id);
  const brand = useBrand(product.brand_id);
  const [moreInfo, setMoreInfo] = useState(false);

  useEffect(() => {
    document.title = `${product.name} - ${brand.name}`;
  }, [brand, product]);

  return (
    <>
      <Navigation />
      <TopCircle   homeMode={false} color={brand.color}>
        <img
         
          src={`/img/products/${product.id}.png`}
          className=" w-48 h-64 md:w-72 md:h-unit-7xl md:ml-48 md:mt-unit-6xl "
          alt={product.name}
        />
      </TopCircle>

      <main>
        <section className="flex flex-col mt-16  md:mt-0 md:justify-center  md:items-end   px-12 min-h-screen">
          <header className="flex h-fit md:w-2/4 ">
            <div className="flex flex-col">
              <h1 className="text-3xl md:text-5xl font-bold ">
                {product.name}
              </h1>
              <p className=" text-md md:text-xl text-gray-500">
                {product.short_description}
              </p>

              <p className="text-sm">{product.description}</p>
            </div>
          </header>

          <div className="md:w-2/4 mt-6">
            <h5 className="font-semibold">Presentaciones</h5>

            <div className="flex  md:flex-row gap-4">
              {product.presentations.map((presentation) => (
                <span>{presentation}kg</span>
              ))}
            </div>
          </div>

         {product.benefits  && <ul className="md:w-2/4 mt-8 list-disc">
            {product.benefits.split(",").map((benefit) => (
              <li className="text-xs mb-1">{benefit}</li>
            ))}
          </ul>}

          <div className="md:w-2/4 mt-8">
            {!moreInfo ? (
              <Button
                className={`text-white px-4`}
                style={{ backgroundColor: brand.color }}
                size="small"
                shadow
                onClick={() => setMoreInfo(true)}
              >
                Ver más
              </Button>
            ) : (
              <>
                <h5 className="font-semibold mb-3">
                  Porciones diarias recomendadas
                </h5>
                {product.portions_by_activity && (
                  <PortionsByActivity portions={product.portions_by_activity} />
                )}

                {product.portions_by_age && (
                  <PortionsByAge portions={product.portions_by_age} />
                )}

                <Button
                  className={`text-white px-4 mt-8`}
                  style={{ backgroundColor: brand.color }}
                  size="small"
                  shadow
                  onClick={() => setMoreInfo(false)}
                >
                  Ver menos
                </Button>
              </>
            )}
          </div>
        </section>
      </main>
    </>
  );
}

export default Product;
