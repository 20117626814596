import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Input,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from "@nextui-org/react";
import { SearchIcon } from "./icons/SearchIcon.jsx";
import ACLogo from "./ACLogo.jsx";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOnTop, setIsOnTop] = useState(true);
  const menuItems = ["Inicio", "Marcas", "Productos", "Animales"];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 220) {
        setIsOnTop(false);
      } else {
        setIsOnTop(true);
      }
    });
  } ,[]);

  return (
    <Navbar
    shouldHideOnScroll
     isBlurred={false}
     className={`${isOnTop ? "bg-transparent" : "bg-white"} ${isMenuOpen ? "bg-default-500/20 dark:bg-default-500/20" : ""
    } overflow-hidden`}
    isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
      <NavbarContent justify="start">
        <NavbarBrand className="mr-4 ">
          <div className="flex items-center  md:bg-transparent rounded-xl px-4 ">
            <NavbarMenuToggle
              className="text-white md:hidden"
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            />
            <ACLogo width={80} height={80} variant={isOnTop ? 1 : 2} />
          </div>
        </NavbarBrand>
      </NavbarContent>
      <NavbarContent justify="center" className="hidden sm:flex gap-3">
        <NavbarItem>
          <Link className="text-gray-500 font-regular" to={"/"}>
            Inicio
          </Link>
        </NavbarItem>

        <NavbarItem>
          <Link className="text-gray-500 font-regular" to={"/marcas"}>
            Marcas
          </Link>
        </NavbarItem>

        <NavbarItem>
          <Link className="text-gray-500 font-regular" to={"/productos"}>
            Productos
          </Link>
        </NavbarItem>

        <NavbarItem>
          <Link className="text-gray-500 font-regular" to={"/inicio"}>
            Animales
          </Link>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent as="div" className="items-center" justify="end">
        <Input
          classNames={{
            base: "max-w-full sm:max-w-[10rem] h-10",
            mainWrapper: "h-full",
            input: "text-small",
            inputWrapper:
              "h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20",
          }}
          placeholder="Buscar producto..."
          size="sm"
          startContent={<SearchIcon size={18} />}
          type="search"
        />
      </NavbarContent>

      <div className="hidden md:flex gap-5">
        <FontAwesomeIcon className="text-gray-500 text-xl" icon={faWhatsapp} />

        <FontAwesomeIcon className="text-gray-500 text-xl" icon={faFacebook} />

        <FontAwesomeIcon className="text-gray-500 text-xl" icon={faPhone} />
      </div>

      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link className="w-full text-gray-500" to={`/${item}`} size="lg">
              {item}
            </Link>
          </NavbarMenuItem>
        ))}

        <NavbarMenuItem >
          <div className="flex gap-5 mt-5">
            <FontAwesomeIcon
              className="text-gray-500 "
              icon={faWhatsapp}
            />

            <FontAwesomeIcon
              className="text-gray-500 "
              icon={faFacebook}
            />

            <FontAwesomeIcon className="text-gray-500 " icon={faPhone} />
          </div>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}
