import { useContext, useState } from "react";
import { ProductsContext } from "../context/Products";

const useProduct = (id) => {
  const { products } = useContext(ProductsContext)
  
  const [product] = useState(products.find((product) => product.id === id));



  return product;
};

export default useProduct;
