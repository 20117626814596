import React from "react";
import { useNavigate } from "react-router-dom";
import ProductViewTransition from "../lib/viewTransitions/Product/Product";

export default function ProductCard({ product, text = "gray" }) {

  const textColor = {
    gray: "text-gray-500",
    white: "text-white",
  }

  const navigate = useNavigate();
  return (
    <div className="cursor-pointer" onClick={() => ProductViewTransition(product.id, navigate)}>
      <div className="flex flex-col items-center justify-center">
        <img
          src={`./img/products/${product.id}.png`}
          alt={product.name}
          className="w-32 h-44 rounded-xl"
          
        />
        <h3 className={`text-xl font-semibold ${textColor[text]}`}>{product.name}</h3>
        <p className={`text-xs ${textColor[text]}`}>{product.short_description}</p>
      </div>
    </div>
  );
}
