import React from 'react'

function ACLogo({variant, width = 50, height = 50}) {


  return (
   <> 
        <img width={width} height={height} src={`/img/logo${variant}.svg`} alt="" />
   </>
  )
}

export default ACLogo