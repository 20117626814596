export default function ProductViewTransition(id, navigate) {

    if(document.startViewTransition) {
        document.startViewTransition(()=>{
            updateView(id, navigate);
        })

    } else {
        updateView(id, navigate);
    }


}

const updateView = (id, navigate) => {
    navigate(`/producto/${id}`);
}